<template>
  <div>
    <!-- MISSING: picB0A424A3F84D3E30CCE935A8A151A532.jpg -->
    <!-- MISSING: DICT: download45F0B8ED59F89D341C1E53771729B013.swf -->
    <img style="width:680px;height:490px;" class="rounded" src="">

    <p>
      <b>A new look into LEGO Universe building!</b>
      <br>Look into this new LEGO Universe visual! This graphic represents the building fun you LEGO fans will find in the first MMOG made especially for you!
    </p>
    <p>
      Wield an imaginative arsenal to battle the forces of chaos in LEGO Universe-- no matter whether you prefer fast-and-furious builds or creating custom LEGO models!
    </p>
  </div>
</template>
